<!--
	Last modified: 2022/10/19 11:53:00
-->
<script>
const tempAxios = require('axios').default.create();

export default {
	name: 'AxiosForm',
	functional: true,
	props: {
		tag: {
			type: String,
			default: 'form',
		},
		axios: {
			type: Function,
			default: tempAxios,
		},
		dataAppendage: {
			type: Object,
			default: null,
		},
		useNativeFormDataOnPost: {
			type: Boolean,
			default: false,
		},
		dataTransformation: {
			type: Function,
			default: (e) => e,
		},
		config: {
			type: Object,
			default: () => ({}),
		},
		disable: {
			type: Boolean,
			default: false,
		},
	},
	render(h, { props, slots, data }) {
		const Tag = props.tag;

		Object.assign(data, { on: data.on || {} });
		const { submit } = data.on;
		data.on.submit = (e) => {
			if (!props.disable) {
				submit?.(e);
				if (!e.defaultPrevented) {
					let { action, method } = data?.attrs || {};
					const { target, submitter } = e || {};
					action = action || submitter.attributes?.formaction || '';
					method = (
						method ||
						submitter.attributes?.formmethod ||
						'get'
					).toLowerCase();

					/*
						We do not append the form action
						until we are ready for JS.
					*/
					if (
						process.client &&
						action &&
						['get', 'post'].includes(method) &&
						props.axios
					) {
						let formData = new FormData(target);
						/*
							To allow for checkbix lists, we need to scrabe
							the contents for reuses of the names.
						*/
						for (const [key, value] of formData.entries()) {
							const multiInputs = [
								...target?.querySelectorAll?.(
									`input[name=${key}]:checked, textarea[name=${key}]:checked, select[name=${key}]:checked, datalist[name=${key}]:checked`
								),
							];
							if (multiInputs.length > 1) {
								const newValue =
									multiInputs
										.map((input) => input.value)
										.filter(Boolean)
										.join(',') || value;
								formData.set(key, newValue);
							}
						}

						/*
							Sometimes we want to append data to the
							request.
						*/
						if (props.dataAppendage) {
							for (const key in props.dataAppendage) {
								formData.set(
									key,
									String(props.dataAppendage[key])
								);
							}
						}

						/*
							Oftentimes we do not want to use the
							actual formData - instead we convert
							it to a regular object.
						*/
						if (
							!props.useNativeFormDataOnPost ||
							method === 'get'
						) {
							const data = {};
							for (const [key, value] of formData.entries()) {
								data[key] = value;
							}
							formData = data;
						}

						/*
							Manual transformation of the data
							before submitting to the endpoint.
						*/
						formData = props.dataTransformation(formData);

						const config = {
							...props.config,
							headers: Object.assign(
								{},
								props.axios?.defaults?.headers?.common,
								props.config?.headers || {}
							),
						};
						if (method === 'get') {
							let string = action.indexOf('?') >= 0 ? '&' : '?';
							for (const key in formData) {
								string += `${key}=${formData[key]}&`;
							}
							string = string.substring(0, string.length - 1);
							data.on.request?.(formData);
							props.axios
								.get(`${action}${string}`, config)
								.then((response) => {
									data.on.response?.(response);
									data.on.complete?.(response);
								})
								.catch((error) => {
									data.on.response?.(error?.response);
									data.on.error?.(error);
								});
						}
						if (method === 'post') {
							if (bindings?.attrs?.enctype) {
								config.headers['Content-Type'] =
									bindings.attrs.enctype;
							}

							data.on.request?.(formData);
							props.axios
								.post(action, formData, config)
								.then((response) => {
									data.on.response?.(response);
									data.on.complete?.(response);
								})
								.catch((error) => {
									data.on.response?.(error?.response);
									data.on.error?.(error);
								});
						}
					}
				}
			}
			e.preventDefault();
		};

		const bindings = {
			...data,
			class: ['c-axios-form', data.staticClass, data.class],
			staticClass: '',
		};

		if (process.server && bindings?.attrs) {
			bindings.attrs.action = '';
		}

		const html = data?.domProps?.innerHTML;
		return html ? (
			<Tag {...bindings} domPropsInnerHTML={html} />
		) : (
			<Tag {...bindings}>
				{data?.domProps?.textContent || slots().default}
			</Tag>
		);
	},
};
</script>
