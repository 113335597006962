<template>
	<div class="c-related-pages">
		<div class="w-full bg-sand px-layout-margin pt-lg pb-xl">
			<BaseH2 class="text-primary-button mb-sm" v-text="title" />

			<div
				:class="[
					'grid grid-cols-1 >=768:grid-cols-3',
					'gap-x-layout-gutter gap-y-md',
				]"
			>
				<ContentCard
					v-for="(item, index) in items"
					:key="`card-${index}`"
					:to="item.url"
					:target="item.target"
					:name="item.name"
					size="custom"
					light
				/>
			</div>
		</div>
	</div>
</template>

<script>
import ContentCard from '~/components/shared/ContentCard';

export default {
	name: 'RelatedPages',
	components: { ContentCard },

	props: {
		title: {
			type: String,
			required: false,
		},
		items: {
			type: Array,
			required: true,
		},
	},
};
</script>

<style lang="postcss">
.c-related-pages .c-content-card__content {
	@apply p-md py-sm;
}
</style>
